import React from "react";
import styled from "styled-components"

const ProjectContainer = styled.div`
  background-image: url('${props => props.img}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${props => props.pos ? props.pos : "center"};
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  min-height: 400px;
  overflow: hidden;
  @media only screen and (min-width: 1920px) {
  min-height: 600px;
  }

`
const ProjectOverlay = styled.div`
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color 400ms;
  ${ProjectContainer}:hover & {
      background-color: rgba(0, 0, 0, .8);

  }
`

const ProjectDetails = styled.div`
position: absolute;
bottom: 0;
padding: 0 0 0 1rem;
z-index: 15;
width: 100%;
transform: translate3d(0, 85%, 0);

@media only screen and (min-width: 768px) {
transform: translate3d(0, 80%, 0);
}
@media only screen and (min-width: 960px) {
transform: translate3d(0, 90%, 0);
}
@media only screen and (min-width: 1024px) {
transform: translate3d(0, 85%, 0);
}
@media only screen and (min-width: 2560px) {
transform: translate3d(0, 80%, 0);
}
transition: transform 400ms;
  h6 {
  color: #fff;
  }
  p {
  color: #fff;
  margin: 1rem 0 0 0;
  }
  ${ProjectContainer}:hover & {
    transform: translate3d(0, 0, 0);
  }  
`

const Project = (props) => {

  return (
    <ProjectContainer {...props}>
      <ProjectOverlay/>
      <ProjectDetails>
        {props.children}
      </ProjectDetails>
    </ProjectContainer>
  )
}

export default Project;