import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
const ButtonContainer = styled.div`
  
  margin: ${props => props.m ? props.m : "1rem 0 1rem 0" };
  a {
    border: 1px solid transparent;
    background-color: #008DD5;
    color: #fff;
    padding: .5rem 1rem .5rem 1rem;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    &:hover {
      background-color: #094074;
      border: 1px solid #094074;
      color: #fff;
    }
    &:focus {
      outline: none;
      background-color: #094074;
      border: 1px solid #094074;
      color: #fff;
    }
  }

`

const ExternalButton = (props) => {


  return (
    <ButtonContainer {...props}>
      <a href={props.to ? props.to : "#"} type="button">{props.children}</a>
    </ButtonContainer>
  )
}

export default ExternalButton;