import React, { useCallback, useEffect, useRef, useState } from "react"
import styled, {keyframes} from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SkillBadge from "./SkillBadge"
import RocketButton from "./RocketButton"
import Button from "./Button"
import Cloud from "./Cloud"
import Project from "./Project"
import FS from "../images/fs-1.jpg";
import PD from "../images/ps-2.jpg";
import AirService from "../images/airservice-2.jpg";
import BV from "../images/bv-1.jpg"
import Aristeus from "../images/aristeus-1.jpg"

const HeaderText = styled.h1`
  font-family: 'Quantico', sans-serif;
  color: #fff;
  z-index: 20;
  position: relative;

`

const MainProjectContainer = styled.div`
    -webkit-box-shadow: 0 10px 6px -6px #000;
  -moz-box-shadow: 0 10px 6px -6px #000;
  box-shadow: 0 10px 6px -6px #000;
  padding: .5rem 1rem;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  z-index: 20;
  margin: 0 0 3rem 0;
`

const SectionContainer = styled.section`
    background: #136a8a;
    background: -webkit-linear-gradient(45deg #004e92, #000428);
    background: linear-gradient(45deg, #004e92, #000428);
    padding: 1px 0 0 0;
    position: relative;

`
const WaveContainer = styled.div`
  margin-top: -1rem;
`

const MainProjectText = styled.div`
  padding: 2rem 0 2rem 0;
  position: relative;
  @media only screen and (min-width: 960px) {
    padding: 2rem 0 2rem 2rem;

  }

`

const BadgeContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
`
const CloudContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 5;

`


let previousY = 0
let previousRatio = 0

const handleIntersect = (entry) => {
  const currentY = entry.boundingClientRect.y
  const currentRatio = entry.intersectionRatio
  const isIntersecting = entry.isIntersecting

  // Scrolling down/up
  if (currentY < previousY) {
    if (currentRatio > previousRatio && isIntersecting) {
      return false;
      //move clouds because scrolling down enter
    } else {
      return false;
    }
  } else if (currentY > previousY && isIntersecting) {
    if (currentRatio < previousRatio) {
      return true;
    } else {
      return true;
      // move clouds
    }
  }

  previousY = currentY
  previousRatio = currentRatio
}

//const Thresholds = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1];
const Thresholds = [.1, .2, .3, .4, .5, .6, .7, .8, .9]
// layers of the atmosphere
// make clouds move across projects section

const HomeProjects = (props) => {
  const [intersection, setIntersection] = useState(0.0);
  const sectionRef = useRef();
  const images = useStaticQuery(graphql`
      query ProjectImagesQuery {
          file(relativePath: { eq: "hr1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  const handleScroll = (entries, observer) => {

    entries.forEach(entry => {
      if(entry.isIntersecting && handleIntersect(entry)) {
        requestAnimationFrame((ts) => {setIntersection(entry.intersectionRatio);});
        console.log('is intersecting, updating ', entry.intersectionRatio)
      } else {
        console.log('not intersection: ', entry.intersectionRatio);

      }
      if(entry.intersectionRatio > .7) {
        observer.unobserve(sectionRef.current);
      }

    })

  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: Thresholds
    });
    observer.observe(sectionRef.current);
  }, [sectionRef])


  return (
    <SectionContainer ref={sectionRef} id="projects-section" >
      <WaveContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#e6e6e6"
                d="M0,160L48,149.3C96,139,192,117,288,133.3C384,149,480,203,576,202.7C672,203,768,149,864,154.7C960,160,1056,224,1152,240C1248,256,1344,224,1392,208L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
        </svg>
      </WaveContainer>

      <HeaderText className="sectionHeader">Projects</HeaderText>

      <CloudContainer>
        <Cloud x={intersection} t="-15vh" l="-8vw" w="128px"  />
        <Cloud x={intersection} t="-10vh" />
      </CloudContainer>


      <div className="container fluid-mobile">
        <MainProjectContainer>
          <div className="grid">
            <div className="column is-5-md is-12">
              <Img fluid={images.file.childImageSharp.fluid} alt=""/>
            </div>
            <div className="column is-7-md is-12">
              <MainProjectText>
                <h6>Human Resources Application</h6>
                <p>In an effort to create a fully in house and custom solution for the Human Resources department's growing
                administrative burden, I was contracted to create this application. Using Laravel as the backend with a React front
                end, I was able to created a fully digitized personnel file, with features like employee performance evaluations,
                file uploads, reminders, and wage reporting.</p>
                <BadgeContainer>
                  <SkillBadge>HTML5</SkillBadge>
                  <SkillBadge>CSS3</SkillBadge>
                  <SkillBadge>JavaScript</SkillBadge>
                  <SkillBadge>Laravel</SkillBadge>
                  <SkillBadge>React</SkillBadge>
                </BadgeContainer>
                <Button m="3rem 0 1rem 0" to="/hr">See Case Study</Button>
              </MainProjectText>
            </div>
          </div>

        </MainProjectContainer>
      </div>






    </SectionContainer>

  )

}


export default HomeProjects