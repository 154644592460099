import React from "react"
//import { Link } from "gatsby"
//import SEO from "../components/seo"
import "./site.scss"
import HomeIntro from "../components/HomeIntro"
import HomeSkills from "../components/HomeSkills"
import HomeProjects from "../components/HomeProjects"
import Modal from "../components/Modal"
import OtherProjects from "../components/OtherProjects"
import HomeInfo from "../components/HomeInfo"


class IndexPage extends React.Component {


  componentDidMount() {

  }

  render() {
    return (

      <React.Fragment>
        <HomeIntro/>
        <HomeSkills/>
        <HomeProjects/>
        <OtherProjects/>
        <HomeInfo/>
      </React.Fragment>



    )
  }

}


export default IndexPage