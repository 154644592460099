import React from "react"
import styled, { css, keyframes } from "styled-components"


const FadeIn = keyframes`
from {
opacity: 0;
}
to {
opacity: 1;
}
`



const StyledStar = styled.object.attrs(props => ({
  style: {
    transform: `translate3d(${props.mouseX / 25}px, ${props.mouseY / 25}px, 0)`,
  }
}))
  `
  position: absolute;
  z-index: 1;
  opacity: 0;
  animation-name: ${FadeIn};
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 4s;
  @media only screen and (max-width: 1024px) {
    ${props => props.hm ? "display: none" : "inherit"};
  }
  left: ${props => {
  return props.left
}};
  right: ${props => {
  return props.right
}};
  top: ${props => {
  return props.top
}};
  bottom: ${props => {
  return props.bottom
}};
  width: ${props => {return props.w}};
  height: ${props => {return props.h}};
  transition: transform 100ms;

`







const Star = (props) => {
  const delay = Math.round(4 * (Math.random() * 4) ) + "s";

return (
  <React.Fragment>
    <StyledStar delay={delay} type="image/svg+xml" className="star" {...props} data={props.source} alt="X"/>
  </React.Fragment>

)
}

export default Star