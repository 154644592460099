import React from "react";
import styled from "styled-components"

const Container = styled.div`
  width: auto;
  border: ${props => props.b ? props.b : "1px solid #008DD5"};
  background: ${props => props.bk ? props.bk : "transparent"};
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding: .5rem 1rem .5rem 1rem;
  transition: border 400ms, background-color 400ms, color 400ms;
  border-radius: 5px;
      margin: 1rem 0 0 0;

   @media only screen and (min-width: 960px) {
    margin: 0 0 0 0;

  }
  &:hover {
    border: 1px solid #094074;
    background-color: #094074;
    color: #fff;
  }


`

const SkillBadge = (props) => {


  return (
    <Container {...props}>
      {props.children}
    </Container>
  )

}


export default SkillBadge;