import React from "react"
import styled, { css, keyframes } from "styled-components"
import Rocket from "../images/Rocket.svg"

const ButtonContainer = styled.div`
  position: relative;
  width: auto;
  display: inline-block;
  margin-top: 1rem;
  border: 1px solid transparent;
  transition: border 600ms, padding 600ms;
  &:hover {
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;

    transition: border 600ms, padding 600ms;
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
`

const Button = styled.button`
  background-color: #fff;
  border-color: transparent;
  border-width: 1px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  padding-bottom: calc(.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(.5em - 1px);
  white-space: nowrap;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 3;
  font-size: 1.2rem;
  font-weight: 700;
  transition: transform 500ms, opacity 500ms;
  font-family: 'Quantico', sans-serif;

  ${ButtonContainer}:hover & {
    opacity: 0;
    transform: scale(1.5);
    transition: transform 500ms, opacity 500ms;
  }
`

const RocketAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50px);
  }

`


const RocketImage = styled.img`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100px;
  opacity: 0;
 

  ${ButtonContainer}:hover & {
    opacity: 1;
    transition: opacity 1500ms, transform 3000ms;
    animation-name: ${RocketAnimation};
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
`


const RocketButton = (props) => {


  return (
    <ButtonContainer>
      <a href="#footer">
        <Button>{props.children}</Button>
        <RocketImage src={Rocket}/>
      </a>

    </ButtonContainer>
  )

}

export default RocketButton