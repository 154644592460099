import React from "react";
import styled, { keyframes } from "styled-components"

//https://stackoverflow.com/questions/6665997/switch-statement-for-greater-than-less-than
const calculateMovementAmount = (x) => {
  //const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (x > 0 && x < .20) {
    return 0;
  }
  if (x >= .20 && x < .30) {
    return Math.round(x * 500);
  }
  if (x > .30 && x < .40) {
    return Math.round(x * 750);
  }
  if (x > .50 && x < .60) {
    return Math.round(x * 1000);
  }
  if (x > .60 && x < .70) {
    return Math.round(x * 1500);
  }
  if (x > .70) {
    return Math.round(x * 2000);

  }
  return Math.round(x * 1000);
}

const CloudContainer = styled.div.attrs(props => ({
  style: {
    transform: `translate3d(${calculateMovementAmount(props.x)}px, 0, 0)` || "translate3d(0, 0, 0)",
    top: `${props.t}` || "inherit",
    left: `${props.l}` || "inherit",
  }
}))`
  width: auto;
  transition: transform 500ms;
position: absolute;
z-index: 5;
 .cloud-svg {
    fill: #fff;
    max-width: 256px;
    width: ${props => props.w ? props.w : "192px"};
    margin: ${props => props.m ? props.m : "0 0 0 0"};
    //transform: translate3d(-256px, 0, 0);
    }
`

const Cloud = (props) => {

  return (
    <CloudContainer {...props}>
      <svg className="cloud-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 217.7 125.9">
        <path className="st0" d="M200.3,76c0-0.7,0.1-1.3,0.1-2c0-18.6-15-33.6-33.6-33.6c-3.5,0-6.9,0.5-10.1,1.5c0-0.1,0-0.2,0-0.4
	c0-23-18.6-41.6-41.6-41.6C96.8,0,81.2,11.8,75.7,28.3c-4.8-2.9-10.5-4.6-16.5-4.6C41.4,23.7,27,38.1,27,55.9c0,2,0.2,4,0.6,6
	C12,64.1,0,77.5,0,93.7c0,17.8,14.4,32.2,32.2,32.2h159.9c14.1,0,25.6-11.5,25.6-25.6C217.7,89,210.4,79.4,200.3,76z"/>
      </svg>
    </CloudContainer>
  )
}

export default Cloud;