import React from "react"
import styles from "./styles/HomeSkills.module.scss"
import CSS from "../images/CSS3.svg";
import HTML5 from "../images/HTML5.svg";
import JS from "../images/Javascript.svg";
import VUE from "../images/Vue.svg";
import REACT from "../images/React.svg";
import LARAVEL from "../images/Laravel.svg";
import WORDPRESS from "../images/Wordpress.svg";
import TERMINAL from "../images/Terminal.svg"
import styled from "styled-components"
import Card from "./Card"
import {Skills} from "./Skills";



export default class HomeSkills extends React.Component {


  render() {
    return (
      <section className={styles.background + " section"}>
        <div className={styles.sectionPadding}>
          <h1 className={styles.sectionHeader}>Skills</h1>
          <p className={styles.sectionSubtitle}>Bars, charts, and stars mean nothing. Click and read honest descriptions!</p>
          <div className="container ">
            <div className="grid">
              <div className="column is-4-md is-12">
                <Card color="orange" expandedContent={Skills.html5}>
                  <img className={styles.skillLogo} src={HTML5} alt="HTML5 Logo"/>
                </Card>
              </div>
              <div className="column is-4-md is-12">
                <Card color="blue" expandedContent={Skills.css}>
                  <img className={styles.skillLogo} src={CSS} alt="CSS3 Logo"/>
                </Card>

              </div>
              <div className="column is-4-md is-12">
                <Card color="yellow" expandedContent={Skills.js}>
                  <img className={styles.skillLogo} src={JS} alt="Javascript Logo"/>
                </Card>
              </div>

            </div>



            <div className="grid">
              <div className="column is-4-md is-12">
                <Card color="sky" expandedContent={Skills.reactSkill}>
                  <img className={styles.skillLogo} src={REACT} alt="React Logo"/>
                </Card>
              </div>
              <div className="column is-4-md is-12">
                <Card expandedContent={Skills.vue} color="teal">
                  <img className={styles.skillLogo} src={VUE} alt="Vue Logo"/>
                </Card>

              </div>
              <div className="column is-4-md is-12">
                <Card expandedContent={Skills.laravel} color="darkorange">
                  <img className={styles.skillLogo} src={LARAVEL} alt="Laravel Logo"/>
                </Card>
              </div>

            </div>

            <div className="grid">
              <div className="column is-4-md is-12">
                <Card expandedContent={Skills.wordpress} color="orange">
                  <img className={styles.skillLogoLargeWidth} src={WORDPRESS} alt="Wordpress Logo"/>
                </Card>
              </div>
              <div className="column is-4-md is-12">
                <Card expandedContent={Skills.linux} color="purple">
                  <img className={styles.skillLogo} src={TERMINAL} alt="Terminal icon"/>
                </Card>
              </div>
              <div className="column is-4-md is-12">

              </div>

            </div>

          </div>
        </div>

      </section>
    )
  }


}