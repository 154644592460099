import React from "react"
import styled from "styled-components"
import Project from "./Project"
import FS from "../images/fs-1.jpg"
import SkillBadge from "./SkillBadge"
import Button from "./Button"
import ExternalButton from "./ExternalButton"
import AirService from "../images/airservice-2.jpg"
import PD from "../images/ps-2.jpg"
import Aristeus from "../images/aristeus-1.jpg"
import BV from "../images/bv-1.jpg"
import { Link, useStaticQuery } from "gatsby"

const OtherProjectsContainer = styled.div`

`

const MiniBadges = styled.div`
width: auto;
display: flex;
flex-direction: row;
*:first-child {
 margin: 0 1rem 0 0;

}
 *:not(:first-child) {
 margin: 0 1rem 0 1rem;
 }
`

const ProjectButtonContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`
const ProjectButton = styled(ExternalButton)`
&:first-child {
margin: 1rem .5rem 1rem 0;
}
margin: 1rem .5rem 1rem .5rem;
`


const OtherProjects = (props) => {
  const portfolioImage = useStaticQuery(graphql`
      query {
          file(relativePath: {eq: "portfolio.jpg"}) {
              id
              childImageSharp {
                  fluid(maxWidth: 900) {
                      src
                  }
              }
          }
      }
  `)

  return (

    <OtherProjectsContainer>

        <div className="grid mobile">
          <div className="column is-4-md is-12 no-gutter">
            <Project img={FS} pos="left">
              <h6>Concentration Logs</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">React</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">Express</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">Material UI</SkillBadge>

              </MiniBadges>
              <p>This application allows users in the field to constantly monitor various measures of the water while alerting
                the department head of critical values in real time.</p>
              <Button to="/concentrations">Case Study</Button>
            </Project>
          </div>
          <div className="column is-4-md is-12 no-gutter">
            <Project img={AirService}>
              <h6>Air Service Experts</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">Gatsby</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">Netlify</SkillBadge>

              </MiniBadges>
              <p>Air Service Experts was one of my first clients when I first started web development. Recently, I went ahead and
                remade the website to bring it up to my current standards.</p>
              <ProjectButtonContainer>
                <ProjectButton to="https://airserviceexperts.netlify.app/">New Site</ProjectButton>
                <ProjectButton to="http://airserviceexperts.com/">Original Site</ProjectButton>
              </ProjectButtonContainer>



            </Project>
          </div>

          <div className="column is-4-md is-12 no-gutter">
            <Project img={PD} pos="left">
              <h6>Purchase Order Tracking</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">React</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">Express</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">Material UI</SkillBadge>
              </MiniBadges>
              <p>The application written for the Purchasing Department allows users from across departments to keep track
                of their various orders from multiple vendors all in one location.</p>
              <Button to="po">Case Study</Button>
            </Project>
          </div>
        </div>

        <div className="grid no-margin mobile">
          <div className="column is-4-md is-12 no-gutter">
            <Project img={Aristeus} pos="left">
              <h6>Aristeus Technology</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">HTML</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">CSS</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">PHP</SkillBadge>

              </MiniBadges>
              <p>The client wanted a website to represent his Houston based business of PC repair & networking services. I used a simple
              tech stack involving PHP mostly for the ability to "include" the layout.</p>
              <ProjectButton to="https://aristeustechnology.com/">View Website</ProjectButton>
            </Project>
          </div>
          <div className="column is-4-md is-12 no-gutter">
            <Project img={BV}>
              <h6>Bean & Vine Co</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">Wordpress</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">VPS</SkillBadge>

              </MiniBadges>
              <p>Bean & Vine was one of my first ventures into the Wordpress world. Using ACF, I made a custom theme from
              scratch that allowed the client to edit almost every feature of the site from images to text.</p>
              <ProjectButtonContainer>
                <ProjectButton to="https://beanandvine.co/">View Website</ProjectButton>
              </ProjectButtonContainer>



            </Project>
          </div>

          <div className="column is-4-md is-12 no-gutter">
            <Project img={portfolioImage.file.childImageSharp.fluid.src}>
              <h6>This Portfolio</h6>
              <MiniBadges>
                <SkillBadge b="1 px solid #fff" bk="#fff">Gatsby</SkillBadge>
                <SkillBadge b="1 px solid #fff" bk="#fff">SCSS</SkillBadge>

              </MiniBadges>
              <p>I decided to recreate my portfolio page and I choose Gatsby for a variety of reasons. Used a combinination
              of techniques for the effects like requestAnimationFrame & Intersection Observer...</p>
              <ProjectButtonContainer>
                <ProjectButton>Your Here!</ProjectButton>
              </ProjectButtonContainer>



            </Project>
          </div>

        </div>

    </OtherProjectsContainer>
  )
}

export default OtherProjects;