import React from "react"
import styles from "./styles/HomeIntro.module.scss"
import Typewriter from "typewriter-effect/dist/core"
import RightArrow from "../images/R-Arrow.svg"
import RocketButton from "./RocketButton"
import Star from "./Star"
import _ from 'lodash';
import StarSvg1 from "../images/Star.svg";
import StarSvg2 from "../images/Star-2.svg";
import StarSvg3 from "../images/Star-3.svg";
import StarSvg4 from "../images/Star-4.svg";
import StarSvg5 from "../images/Star-5.svg";
import StarSvg6 from "../images/Star-6.svg";
import StarSvg7 from "../images/Star-7.svg";
import Planet from "../images/Planet.svg";
import Planet2 from "../images/Planet-2.svg";
import Comet from "../images/Comet.svg";
import Moon from "../images/Moon.svg";
import styled from "styled-components";

import HTML from "../images/HTML5.svg";

//TODO: Use GraphQL for SVGs
const StopWriter = (elements_container) => {
  const elements = elements_container.elements
  const cursor = elements.cursor
  cursor.parentNode.removeChild(cursor)

}

const DescriptionText = styled.h2`
font-family: 'Quantico', sans-serif;
`
const DescriptionHeader = styled.h4`

`

class HomeIntro extends React.Component {


  componentDidMount() {
    let tw = new Typewriter("#description")
      .pauseFor(200)
      .typeString("Rockstar Ninja Code Master")
      .pauseFor(600)
      .deleteAll("fast")
      .pauseFor(200)
      .typeString("{ Web Developer }")
      .callFunction(StopWriter)
      .start();


  }

  constructor(props) {
    super(props);
    this.state = {
      mouseX: 0,
      mouseY: 0,
    }
    //this.moveStars = _.throttle(this.moveStars.bind(this), 0);
  }

  handleStars = (event) => {
    requestAnimationFrame(this.moveStars.bind(this, event.nativeEvent));
  }

  moveStars = (event, timestamp) => {
    this.setState({
      mouseX: event.clientX,
      mouseY: event.clientY,
    })
  }


//mobile star 4, 5
  render() {
    return (
      <section id="home-intro-container" className={styles.introHero + " section"} onMouseMove={this.handleStars}>
        <div className={styles.gradientLayer}></div>
        <div className={styles.gradientLayerTwo}></div>


        <Star source={Planet} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="5vh" left="33vw" right={0} bottom={0} w="192px" h="192px"
        />


        <Star source={StarSvg2} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="5vh" left="5vw" right={0} bottom={0} w="128px" h="96px"
        />
        <Star source={StarSvg2} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="75vh" left="33vw" right={0} bottom={0} w="128px" h="128px"
              />
        <Star source={Comet} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="25vh" left="66vw" right={0} bottom={0} w="128px" h="128px"
              
        />
        <Star hm={true} source={StarSvg4} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="75vh" left="80vw" right={0} bottom={0} w="128px" h="128px"
        />
        <Star hm={true} source={StarSvg5} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="5vh" left="65vw" right={0} bottom={0} w="96px" h="128px"
              />
        <Star source={Moon} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="66vh" left="2vw" right={0} bottom={0} w="64px" h="128px"
              />
        <Star source={Planet2} mouseX={this.state.mouseX} mouseY={this.state.mouseY}
              top="55vh" left="60vw" right={0} bottom={0} w="64px" h="128px"
              />


        <div className="hero h100">
          <div className="container middle margin-bottom-large">
            <DescriptionHeader className="text-white">Ishmael Avila</DescriptionHeader>

            <DescriptionText id="description" className="text-white">Rock Star Ninja Jedi Code Master</DescriptionText>
            <RocketButton>Get In Touch</RocketButton>

            <div className="shape-holder">

            </div>
          </div>

        </div>
      </section>
    )
  }
}

export default HomeIntro